body {
  margin: 0;
  padding: 0;
  height: 100% !important;
  width: 100%;
  overflow-x: hidden;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  html{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: scroll; 
  }

  img.bg{
      /* Set rules to fill background */
  min-height: 100%;
  min-width: 1024px;
	
  /* Set up proportionate scaling */
  width: 100%;
  height: auto;
	
  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;
  z-index: -5;
  }
  .main{
    background-color:rgba(0,0,0,0.8);
    width: 100%;
    height: 100vh;
    margin:0;
    text-align: center; 
    
  }
  .main-body{
    margin-top:100px;
  }
  .app{
    text-align: center;
    /* Full height */
    height: 100%; 
    
  }
  .router{
    height: 100%;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  /* Navbar */
.nav-bar{
  position: absolute !important;
  top: 0;
  z-index: 10;
  width: 100%;
  overflow: hidden;
}
#contact, #resume, #projects{
  /* padding-top: 30px; */
}
  /* Main Page */
  .home-page{

  }
  a.intro-blog-link{
    color: black !important;
    text-decoration: underline !important;
  }
  
  .align-pic-about{
    text-align: center;
    padding: 30px;
  }

 .hi-header{
    color: rgba(255,255,255,0.85) !important;
    font-weight: 700;
    text-align:center;
  }
  .hi-header2{
    color: rgba(255,255,255,0.85) !important;
    font-weight: 700;
    text-align:center;
  }
  .profile-pic-container{
    display: inline-block;
    text-align: center;
    height: 350px;
    width: 350px;
    margin-right: auto;
  }
  .profile-pic{
    border-radius: 25px;
    border: 1px solid black;
    box-shadow: 5px 5px;
    border-radius: 25px;
    height: 100%;
    width: 80%;
   
  }
  .about-me{
    padding: 1em;
    border-radius: 25px;
    border: 1px solid black;
    box-shadow: 5px 5px;
    background-color: rgba(255,255,255,0.65);
    width: 80%;
    display: inline-block;
    margin-top: -1em;
    line-height: 1.6;
    font-size: 18px;
    vertical-align: top;
  }
  
  /* Footer */
  a {
    text-decoration: none !important
  }
  .footer{
    background-color: "gray";
    position: relative;
    display: flex;
    justify-content: center;
    top: 1.5em;
    left: 0;
    right: 0;  
    bottom: 0.5em; 
    width: 100%;
  }
  .footer-list{
    display: flex;
    justify-content: center;
  }
  .footer-links{
    text-align: center;
  }
  .gh-menu-item,
  .yt-menu-item,
  .md-menu-item,
  .dl-menu-item{
    text-align: center !important;
  }
  .github-icon,
  .youtube-icon,
  .medium-icon, 
  .resume-icon{
    display:flex;
    margin: auto !important;
  }
  .github-icon:hover,
  .youtube-icon:hover,
  .medium-icon:hover{
    cursor: pointer;
  }
  .footer-list li{

  }
  .footer-list a:link, a:visited{
    color: #f2f2f2;
  }
  .footer-list a:hover{
   
  }
  .footer-list a:visited{
  }
  
  /* Contact Page */
  .contact-page{
    /* height: 100vh; */
    margin: auto;
  }
  .contact-header{
    text-align: center;
  }
  .contact-div{
    display: flex;
    justify-content: space-between;
    padding: 10px;
    margin: 40px;
  }
  .email-div, .phone-div, .linkedin-div{
    background-color: rgba(255,255,255,0.65);
    display: flex;
    justify-content: center;
    align-items:center;
    margin: 10px;
    border-radius: 50vw;
    padding: 10px;
    height: 200px;
    width: 200px;
  }
  .L-icon{
    color: rgb(0,114,177);
    margin-left: 40px;
  }
  .phone-popup{
    border-radius: 0;
    opacity: 0.7;
    padding: 2em;
  }
  /* Projects Page */
.projects-page{
    width: 100%;
    margin: 0 auto;
}
.projects-grid{
    text-align: center;
    display: flex;
    justify-content: space-around;
 
}
.projects-header{
    color: rgba(255,255,255,0.85) !important;
    font-weight: 700;
    text-align:center;
}

.weather-app-card, .dd-dice-card, .todays-meal-card,
.sketch-up-card,.backyard-garden-card{
  height: 100%;
}
/* Resume */
html, body, .app, .app>.resume-page{
  height: 100%;
  margin: 0 auto;
  background-size:cover; 
  /* display: flex;
  align-items: center; */
}
/* Software */
.software-container,
 .methodologies-container,
  .softskills-container{
  padding: 1.5em;
  border-radius: 25px;
  border: 1px solid black;
  box-shadow: 5px 5px;
  background-color: rgba(255,255,255,0.65);
  width: 100%;
  display: inline-block;
  margin:4em;
  line-height: 1.6;
  font-size: 14px;
  vertical-align: top;
}
.education-container,
.employment-container{
  padding: 1.5em;
  border-radius: 25px;
  border: 1px solid black;
  box-shadow: 5px 5px;
  background-color: rgba(255,255,255,0.65);
  width: 100%;
  height:100%;
  /* display: inline-block; */
  /* margin: 20px; */
  line-height: 1.6;
  font-size: 1em;
  /* vertical-align: top; */

}
.resume-doc{
  display:none;
  visibility: hidden; 
}
.react-pdf__Page__canvas{
  margin: auto;
}

.resume-container{
  margin:auto;
  width: 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
}




 
  